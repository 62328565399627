import './Placard.scss';
import type Movie from '../../models/Movie.model';
import { NavOption } from "../../models/NavOption.model";
import disney from "../../assets/images/disney.png";

type Props = { 
  data: Movie;
  selectedTab: NavOption;
};

export const Placard = ({data, selectedTab}: Props) => {
  return (
    <article className="placard-container">
      <p className="title">{data.title}
        {data.year && <span className="year">{` (${data.year})`}</span>}
        {(selectedTab === NavOption.Collection && data.wishList) ? <span className="wish-list">Wish List</span> : null}
        {(selectedTab === NavOption.WishList && !data.wishList) ? <span className="wish-list">Collected</span> : null}
      </p>
      {data.isDisney && <img className="disney" src={disney}/>}
    </article>
  )
}
