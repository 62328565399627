import './Nav.scss';
import { NavOption } from "../../models/NavOption.model";

interface Props {
    selectedTab: NavOption;
    setSelectedTab: Function;
    resultCount: number;
    loading: boolean;
    getCollection: Function;
}

export const Nav = ({selectedTab, setSelectedTab, resultCount, loading, getCollection} : Props) => {
  const navItems = [
    {label: 'Collection', href: '...', option: NavOption.Collection},
    {label: 'Wish List', href: '...', option: NavOption.WishList},
    // {label: 'About', href: '...', option: NavOption.About},
  ];

  function handleClick(option: NavOption) {
    const list = document.getElementById('dvd-list');
    if (list) list.scroll(0,0);
    if (selectedTab !== option) setSelectedTab(option);
  }

  return (
    <>
    <nav aria-label="main" className="bar-nav">
        <ul className="site-menu-container">
        {navItems.map((n) => <li key={n.label} className={`site-menu-item ${selectedTab === n.option && 'selected'}`}>
        <button onClick={() => handleClick(n.option)}>{n.label}</button></li>)}
        </ul>
    </nav>
     <div className="results-bar">{`${loading ? 'Loading...' : 
      selectedTab === NavOption.About ? 'About' :
      `${resultCount.toLocaleString() ?? 0} ${resultCount === 1 ? 'Result' : 'Results'}` }`}</div>
    </>
  )
}
