import { useEffect, useRef, useState } from 'react';
import './Loader.scss';
import dvd from '../../assets/images/dvd_new.svg';

interface Props {
    app: React.MutableRefObject<Element>;
}

export const Loader = ( props: Props ) => {
    const [logoBounded, setLogoBounded] = useState(false);
    const [logoBounds, setLogoBounds] = useState(null);
    const [appBounds, setAppBounds] = useState(null);
    const isMounted = useRef(false);
    const logo = useRef(null);
    const [dimensions, setDimensions] = useState(null);

    let directionX = Math.floor(Math.random() * 2) > 0 ? 'left' : 'right';
    let directionY = Math.floor(Math.random() * 2) > 0 ? 'top' : 'bottom';
       
    useEffect(() => { 
      isMounted.current = true;
    }, []);

    // get bounding info for logo
    useEffect(() => {
      if (isMounted.current && logo.current) {
        setTimeout(() => {
          const bounds = logo.current.getBoundingClientRect();
          setLogoBounds(bounds);
          setDimensions({
            left: Math.ceil(bounds?.left),
            right: Math.ceil(bounds?.right),
            top: Math.ceil(bounds?.top),
            bottom: Math.ceil(bounds?.bottom),
            width: Math.ceil(bounds?.width),
            height: Math.ceil(bounds?.height)
          })

          setLogoBounded(true);
        }, 10)
     }
    }, [logo.current]);

    useEffect(() => {
      if (isMounted.current && logoBounded) {
        if (props.app?.current) {
          const app = props.app.current.getBoundingClientRect();

          let initLeft = (app.width / 2) - (logo.current.width / 2);
          let initTop = (app.height / 2) - (logo.current.height / 2);
      
          logo.current.style.left = initLeft + 'px';
          logo.current.style.top = initTop + 'px';
          logo.current.style.visibility = 'visible';

          setDimensions({...dimensions, left: initLeft});
          setDimensions({...dimensions, top: initTop});

          setTimeout(() => {
            setAppBounds(app);
          }, 10)
        }

        const updateAppBounds = () => {
          setAppBounds(props.app.current.getBoundingClientRect());
        }

        window.addEventListener('resize', updateAppBounds)
    
        return () => {
          window.removeEventListener('resize', updateAppBounds)
        }
      }
    }, [logoBounded]);
  
    useEffect(() => {
      if (isMounted.current && appBounds) {
        window.requestAnimationFrame(step);
      }
    }, [appBounds])

    function step() {  
      if (logo?.current) {
        if (directionX === 'left') {
          setDimensions({...dimensions, left: --dimensions.left})
          setDimensions({...dimensions, right: --dimensions.right})
          logo.current.style.left = --dimensions.left + 'px';
        } else if (directionX === 'right') {
          setDimensions({...dimensions, left: ++dimensions.left})
          setDimensions({...dimensions, right: ++dimensions.right})
          logo.current.style.left = ++dimensions.left + 'px';
        } 
    
        if (dimensions.left <= 0 && directionX === 'left') {
          directionX = 'right';
          setDimensions({...dimensions, left: ++dimensions.left})
          setDimensions({...dimensions, right: --dimensions.right})
          logo.current.style.left = ++dimensions.left + 'px';
        } else if ((dimensions.left + dimensions.width) >= appBounds?.width && directionX === 'right') {
          directionX = 'left';
          setDimensions({...dimensions, left: --dimensions.left})
          setDimensions({...dimensions, right: ++dimensions.right})
          logo.current.style.left = --dimensions.left + 'px';
        }
    
        if (directionY === 'top') {
          setDimensions({...dimensions, top: --dimensions.top})
          setDimensions({...dimensions, bottom: --dimensions.bottom})
          logo.current.style.top = --dimensions.top + 'px';
        } else if (directionY === 'bottom') {
          setDimensions({...dimensions, top: ++dimensions.top})
          setDimensions({...dimensions, bottom: ++dimensions.bottom})
          logo.current.style.top = ++dimensions.top + 'px';
        }
    
        if (dimensions.top <= 0 && directionY === 'top') {
          directionY = 'bottom';
          setDimensions({...dimensions, top: --dimensions.top})
          setDimensions({...dimensions, bottom: ++dimensions.bottom})
          logo.current.style.top = --dimensions.top + 'px';
        } else if ((dimensions.top + dimensions.height) >= appBounds?.height && directionY === 'bottom') {
          directionY = 'top';
          setDimensions({...dimensions, top: ++dimensions.top})
          setDimensions({...dimensions, bottom: --dimensions.bottom})
          logo.current.style.top = ++dimensions.top + 'px';
        }

      window.requestAnimationFrame(step);
      }
    }
  
    return (
      <img src={dvd} ref={logo} className="dvd-logo" alt="logo" id="logo"/>
    );
}
    
export default Loader;