import { useRef } from "react";
import  './List.scss';
import { Placard } from '../../components/Placard/Placard';
// import Loader from '../loader/Loader';
import { NavOption } from "../../models/NavOption.model";

export const List = (props) => {
  const listContainer = useRef(null);

  function renderContent () {
    if (props.data.length > 0 && !props.loading && props.selectedTab !== NavOption.About) {
      return (
        <ul className="dvd-list" id="dvd-list">
          {props.data.map(dvd => <li key={dvd.id}><Placard selectedTab={props.selectedTab} data={dvd} /></li>)}
        </ul>
      )
    } else if (props.data.length === 0 && !props.loading) {
      return (
       <p className="no-results">No results found</p>
      )
    }
  }
  return (
    <div className="dvd-list-container" ref={listContainer}>
      {/* {props.loading && <Loader app={listContainer}/>} */}
        {renderContent()}
    </div>
  )
}