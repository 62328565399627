import './Input.scss';

type Props = { 
    stateChanger: Function, 
    term: string,
    id: string,
    placeholder: string,
    name: string,
    autoComplete: string;
    hideLabel: boolean;
};

export const Input = ({stateChanger, term, id, placeholder, name, autoComplete, hideLabel }: Props) => {
    return (
    <>
        <label className={`${name}-search-label search-input-label ${hideLabel && 'hidden'}`} htmlFor={id}>Search</label>
        <input 
            onChange={(event) => { stateChanger(event.target.value)}}
            value={term} 
            autoComplete={autoComplete} 
            className={`${name}-search-input search-input`} 
            type="text" name={`${name}-search-name`}
            id={id}
            placeholder={placeholder}>
        </input>
    </>
    )
}
