import './Header.scss';
// import { useOutsideClick } from '../../helpers/ClickOutside';
import logo from '../../assets/images/logo3.png';

type Props = { 
    loading: boolean;
    setLoading: Function;
  };

export const Header = ({ loading, setLoading }: Props) => {
    function toggleMenu (): void {
        setLoading(!loading)
    }
    function handleHeaderClick (event: React.MouseEvent<HTMLElement, MouseEvent>): void {
        event.stopPropagation();
    };

    return (
    <header className="site-header">
        <div className="left-content">
            <h1 className="title">Our DVDs</h1>
        </div>
        {/* {<button className="dvd-mode" onClick={toggleMenu}>
            <img className="logo" src={logo}></img>
        </button>
        } */}
    </header>
    )
}
