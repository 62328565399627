import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, collection } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDua3KxLGE8HbXGva8e3CwFCeAdEUvaJNs",
  authDomain: "our-dvds.firebaseapp.com",
  projectId: "our-dvds",
  storageBucket: "our-dvds.appspot.com",
  messagingSenderId: "588935514879",
  appId: "1:588935514879:web:b7862fce854f6366971aca"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

async function addMovies (movie) {
  try {
    await addDoc(collection(db, 'dvd-collection'), movie);
  } catch (e) {
    console.log('error: ', e)
  }
}

export function upload () {
  fetch("http://localhost:3000/movies")
  .then((resp) => resp.json())
  .then((data) => {
    data?.forEach((d) => {
      addMovies(d);
    })
  })
}