import './App.scss';
import { Header } from './modules/header/Header';
import { Main } from './modules/main/Main';
import { useEffect, useState, useRef } from "react";

function App() {
  const [loading, setLoading] = useState(true);

  return (
    <div className="app" id="app">
      <Header loading={loading} setLoading={setLoading}/>
      <Main loading={loading} setLoading={setLoading}/>
    </div>
  );
}

export default App;
