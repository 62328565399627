import './Search.scss';
import { Input } from '../../components/Input/Input';

type Props = { 
    term: string;
    setTerm: Function;
};

export const Search = ({ term, setTerm }: Props) => {
    
   return (
        <div className="main-search-container">
            <div className="search">
            <Input 
                term={term} 
                stateChanger={setTerm}
                id="main-search"
                placeholder="Search All"
                name="main"
                autoComplete="false" 
                hideLabel={true}/>
            </div>
            {<button disabled={!term} className="clear-btn" onClick={() => setTerm('')}>Clear</button>}
        </div>
    )
}
